<template>
  <div class="wrapper">
    <div v-if="loading" class="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else class="card">
      <div class="title">
        <div class="caption">{{ $t('change_two_factor_auth') }}</div>
      </div>
      <div class="mt-3 mb-3">
        {{ $t('select_auth') }}
      </div>
      <ul class="twoFaList">
        <li>
          <EmailBind :email="email" :info="info"/>
        </li>
        <li>
          <SmsBind :mobile="mobile" :info="info"/>
        </li>
        <li>
          <GoogleBind :info="info"/>
        </li>
      </ul>
      <div class="mt-4">
        <div class="link link-info">
          <div style="font-size: 14px" @click="cancelled">{{ $t('cancelled') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bindInfo, info } from '../api/2fa'

import GoogleBind from '@/components/google/GoogleBind.vue'
import EmailBind from '@/components/email/EmailBind.vue'
import SmsBind from '@/components/sms/SmsBind.vue'

export default {
  name: 'setting.alter',
  components: {
    GoogleBind,
    EmailBind,
    SmsBind
  },
  data () {
    return {
      email: '',
      info: {},
      loading: false,
      mobile: {
        code: '',
        number: ''
      }
    }
  },
  created () {
    if (this.$route.params.mode === undefined) {
      this.$router.push({
        name: 'setting.index'
      })
      return false
    }
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const retBindInfo = await bindInfo()
      for (const item in retBindInfo.data.binding) {
        this.info[item.toLowerCase()] = retBindInfo.data.binding[item]
      }
      const retInfo = await info()
      this.email = retInfo.data.customer.email
      this.mobile = retInfo.data.customer.mobile
      this.loading = false
    },
    cancelled () {
      this.$router.push({
        name: 'setting.index'
      })
    }
  }
}
</script>
