<template>
  <div :class="[click ? 'item email' : 'item email not-allowed']" @click="redirect">
    <div class="wrapper">
      <div class="title">
        {{ $t('email.name') }}
        <div v-if="!click" class="tag ml-1">{{ $t('default') }}</div>
      </div>
      <div class="content">{{ email }}</div>
    </div>
    <div class="grab">
      <i class="owl-direction-right"></i>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EmailBind',
  props: {
    email: String,
    info: Object
  },
  data () {
    return {
      click: true,
      type: 'email'
    }
  },
  created () {
    if (this.info[this.type] === 1) {
      this.click = false
    }
  },
  methods: {
    async redirect () {
      if (!this.click) {
        return false
      }
      this.$router.push({
        name: 'setting.type.verify',
        params: {
          type: 'email',
          mode: true
        }
      })
    }
  }
}
</script>
