<template>
  <div :class="[click ? 'item google' : 'item google not-allowed']" @click="redirect">
    <div class="wrapper">
      <div class="title">
        {{ $t('google.auth_name') }}
        <div v-if="!click" class="tag ml-1">{{ $t('default') }}</div>
      </div>
      <div class="content">
        <div v-if="Object.prototype.hasOwnProperty.call(info, type)">
          {{ $t('verified') }}
        </div>
        <div v-else>
          {{ $t('unverified') }}
        </div>
      </div>
    </div>
    <div class="grab">
      <i class="owl-direction-right"></i>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GoogleBind',
  props: {
    info: Object
  },
  data () {
    return {
      click: true,
      type: 'google',
      form: {
        auth: {
          code: '',
          msg: {
            status: {
              error: false
            },
            note: ''
          }
        },
        passwordCheck: {
          display: false,
          password: '',
          msg: {
            status: {
              error: false
            },
            note: ''
          }
        },
        bind: {
          code: '',
          msg: {
            status: {
              success: false,
              error: false
            },
            note: ''
          }
        }
      },
      google: {
        secret: '',
        qrCode: ''
      }
    }
  },
  created () {
    if (Object.prototype.hasOwnProperty.call(this.info, this.type) && this.info[this.type] === 1) {
      this.click = false
    }
  },
  methods: {
    redirect () {
      if (!this.click) {
        return false
      }
      if (Object.prototype.hasOwnProperty.call(this.info, this.type)) {
        this.$router.push({
          name: 'setting.type.verify',
          params: {
            type: 'google',
            mode: true
          }
        })
      } else {
        this.$router.push({
          name: 'setting.google.flow',
          params: {
            mode: true
          }
        })
      }
    }
  }
}
</script>
