<template>
  <div v-if="status">
    <div :class="[click ? 'item sms' : 'item sms not-allowed']" @click="redirect">
      <div class="wrapper">
        <div class="title">
          {{ $t('sms.name') }}
          <div v-if="!click" class="tag ml-1">{{ $t('default') }}</div>
        </div>
        <div class="content">
          ({{ mobile.code }}) {{ mobile.number }}
        </div>
      </div>
      <div class="grab">
        <i class="owl-direction-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmsBind',
  props: {
    mobile: Object,
    info: Object
  },
  data () {
    return {
      status: false,
      click: true,
      type: 'sms'
    }
  },
  created () {
    this.status = Object.prototype.hasOwnProperty.call(this.info, this.type)
    if (this.status && this.info[this.type] === 1) {
      this.click = false
    }
  },
  methods: {
    async redirect () {
      if (!this.click) {
        return false
      }
      this.$router.push({
        name: 'setting.type.verify',
        params: {
          type: 'sms',
          mode: true
        }
      })
    }
  }
}
</script>
